<template lang="pug">
.template-recommendation-container.mt-4(
  v-show="enabled && templates.length > 0 && templates.some(({ _id }) => !_id)"
  :ref="paletteRef"
)
  slot(name="title")
    .template-recommendation-title
      p.mb-0 {{ $t('templateRecommendation.carousel.title') }}
      div(v-if="$route.name !== 'new_campaign'")
        om-button.p-0(@click="dismissTemplateRecommendation") {{ $t('templateRecommendation.carousel.closeButtonText') }}
  .swiper-container(v-swiper:mySwiper="swiperOption")
    .swiper-wrapper
      .swiper-slide(v-for="(template, index) in templates" :key="index" v-if="template._id")
        .template-box-wrapper.ssr.cursor-pointer
          template-frame.carousel-template-recommendation-template(
            @observable="addObservable($event.$el)"
            :dimensions="boxDimensions"
            allowSsr
            :template="template"
            @contentLoaded="onContentLoaded"
            :themeKit="getProperThemeKit(template)"
            @inited="updateDimensions"
            @click="showTemplatePreview(template)"
          )
            template(#fallback)
              template-box.mb-0.cursor-pointer(
                :template="template"
                @click="showTemplatePreview(template)"
              )
    .swiper-button-next(tabindex="0" role="button")
    .swiper-right-gradient
    .swiper-button-prev(tabindex="0" role="button")
    .swiper-left-gradient
    .swiper-pagination
</template>

<script>
  import { Swiper as SwiperClass, Navigation, Autoplay } from 'swiper/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import { mapGetters } from 'vuex';
  import 'swiper/swiper-bundle.min.css';

  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import previewParentMixin from '@/mixins/previewParent';
  import paletteMixin from '@/mixins/palette';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import templateRecommendationMixin from '@/mixins/templateRecommendation';
  import { track } from '@/services/xray';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';

  SwiperClass.use([Navigation, Autoplay]);
  const SwiperComponent = getAwesomeSwiper(SwiperClass);

  const { directive } = getAwesomeSwiper(SwiperClass);

  export default {
    components: {
      SwiperComponent,
      TemplateBox,
      TemplateFrame,
    },
    directives: {
      swiper: directive,
    },
    mixins: [
      paletteMixin,
      templateRecommendationMixin,
      previewParentMixin,
      ssrMixin,
      observableCollectionMixin,
    ],
    props: {
      slidesPerView: { default: 4.5 },
      slidesPerViewTabletLg: { default: 3.5 },
      slidesPerViewTabletSm: { default: 2.5 },
      slidesPerViewMobile: { default: 1.5 },
      recommendedTemplates: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        ssrBoxSelector: '.carousel-template-recommendation-template',
        hovered: false,
        enabled: false,
        swiperOption: {
          allowTouchMove: this.isMobile,
          slidesPerView: this.slidesPerViewMobile,
          spaceBetween: 20,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            768: {
              slidesPerView: this.slidesPerViewTabletSm,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: this.slidesPerViewTabletLg,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: this.slidesPerView,
              spaceBetween: 20,
            },
          },
        },
        templates: [],
        isSimilarListing: false,
      };
    },
    apollo: {
      customThemes: {
        query: GET_CUSTOM_THEMES,
        update({ themes }) {
          return themes.custom;
        },
        async result() {
          if (!this.enabled) return;
          await this.fetchAvaliableTemplates();
          if (this.templates.length > 0) {
            track('template-recommendation-carosuel-showed');
          }
        },
      },
    },
    computed: {
      ...mapGetters(['wizardPreferences', 'isMobile']),
      swiper() {
        return this.$refs.mySwiper.$swiper;
      },
      isSimilarTemplate() {
        return !this.wizardPreferences.color;
      },
      themeKit() {
        return this.customThemes?.[0]?.themeKit;
      },
    },
    async mounted() {
      await this.isEnabled();
      if (this.enabled) {
        await this.fetchAvaliableTemplates();
        if (this.templates.length > 0) {
          track('template-recommendation-carosuel-showed');
        }
      }
      this.updateDimensions();
    },
    methods: {
      async dismissTemplateRecommendation() {
        const success = await this.dismissWizardRecommendation();
        if (success) {
          track('template-recommendation-carosuel-dismissed');
          this.enabled = false;
        }
      },
      getMainColor() {
        const { color: wizardColor } = this.wizardPreferences;
        return wizardColor;
      },
      showTemplatePreview(template) {
        const { coupon, goal, list } = this.wizardPreferences;
        const color = this.getMainColor();
        const { theme } = template;
        let heapData = {
          useCaseName: template.name.en,
          theme,
        };
        if (!this.isSimilarTemplate) {
          heapData = {
            ...heapData,
            color,
            coupon,
            goal,
            list,
          };
        } else {
          heapData = {
            ...heapData,
            similarTemplate: true,
          };
        }

        this.$modal.show('template-preview', {
          templateId: template._id,
          theme: this.themeKit ? undefined : theme,
          color: this.themeKit ? undefined : color,
          themeKit: this.getProperThemeKit(template),
          heapTracking: {
            name: 'template-recommendation-selection-carosuel',
            data: heapData,
          },
        });
      },
      getProperThemeKit(template) {
        if (!this.isSimilarListing) return this.themeKit;
        return template.template.themeKit && template.template.themeKit.id === this.themeKit.id
          ? this.themeKit || undefined
          : undefined;
      },
      async isEnabled() {
        if (this.recommendedTemplates?.length) {
          this.enabled = true;
          return;
        }
        this.enabled = await this.isTemplateRecommendationEnabled();
      },
      async getRecommendedTemplates() {
        this.updatePalette(this.wizardPreferences.color);
        return this.getRecommendedUseCases(this.wizardPreferences);
      },
      async fetchAvaliableTemplates() {
        try {
          if (this.recommendedTemplates) {
            this.templates = this.recommendedTemplates;
            return;
          }

          if (this.isSimilarTemplate && this.wizardPreferences.useCase) {
            this.isSimilarListing = true;
            this.templates = await this.getSimilarTemplates(this.wizardPreferences);
          } else {
            this.templates = await this.getRecommendedTemplates();
          }
        } catch (err) {
          console.error('Failed to get template recommendation:', err);
        }
      },
    },
  };
</script>
<style lang="sass">
  @import '../../sass/variables/_colors.sass'
  .template-recommendation-title
    display: flex
    justify-content: space-between
    border-bottom: 1px solid $om-gray-300
    padding-bottom: 8px
    p
      font-size: 18px
      font-weight: 500
      color: $om-gray-700
  .templateHoverContainer
    display: flex
    position: absolute
    top: 0px
    left: 0px
    width: 100%
    height: 100%
    background-color: yellow
  .swiper-button-disabled
    & ~ .swiper-right-gradient,
    & ~ .swiper-left-gradient
      opacity :0
  .swiper
    &-right-gradient,
    &-left-gradient
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)
      width: 2rem
      height: 100%
      position: absolute
      top: 0
      right: 0
      z-index: 9
    &-left-gradient
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)
      left: 0
  .swiper-container
    width: calc(100vw - 63px)
    padding: 16px 0 40px
    @media screen and (min-width: 768px)
      width: calc(100vw - 128px)
    @media screen and (min-width: 992px)
      width: calc(100vw - 148px)
    @media screen and (min-width: 1400px)
      width: calc(100vw - 167px)
    .brand-template-chooser &
      @media screen and (min-width: 768px)
        width: calc(100vw - 50px - 41.1vw)
      @media screen and (min-width: 992px)
        width: calc(100vw - 50px - 30.9vw)
      @media screen and (min-width: 1200px)
        width: calc(100vw - 50px - 324px)
      @media screen and (min-width: 1600px)
        width: calc(100vw - 50px - 20.45vw)
  .swiper-button-next, .swiper-button-prev
    width: 2.75rem!important
    height: 2.75rem!important
    background: white
    border-radius: 100%
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
    &:after
      color: $om-gray-700!important
      font-size: 1rem!important
    &.swiper-button-disabled
      opacity: 0
  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction
    bottom: -2px
  .swiper-pagination-bullet
    width: 12px
    height: 12px
    background: $om-gray-400
    opacity: 1
  .swiper-pagination-bullet-active
    background: $om-orange-500

  .template-recommendation-container
    .brand-themes-template-box
      border-radius: 4px
      width: 100%
    .btn:not(.btn-primary)
      &:active,
      &:hover,
      &:focus
        background-color: transparent
        border-color: transparent
        box-shadow: none
  .template-box-wrapper
    position: relative
    overflow: hidden
</style>
